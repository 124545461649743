import {ApiService} from "./api.service";

export class AccountFusionService extends ApiService {
  constructor() {
    super();
  }

  startAccountFusion(data, show_loader) {
    return this.trigger('fusion/start', data, show_loader).then(response => {
      if (response.data.success) {
        return response;
      } else {
        if (
          typeof response.data !== 'undefined'
          && typeof response.data.message === 'string'
        ) {
          this.$toast.error(response.data.message);
        }

        return false;
      }
    });
  }

  fetchOpenSourceRoleFusionData(show_loader) {
    return this.trigger('fusion/getOpenSourceRoleFusion', null, show_loader).then(response => {
      if (response.data.success) {
        if (response.data.fusionData) {
          this.$store.commit('account_reorganisation/setOpenSourceRoleFusionData', response.data.fusionData);
          return response.data.fusionData;
        }
      } else {
        this.$store.commit('account_reorganisation/initSourceRole');
        return [];
      }
    });
  }

  fetchOpenTargetRoleFusionData(show_loader) {
    return this.trigger('fusion/getOpenTargetRoleFusion', null, show_loader).then(response => {
      if (response.data.success) {
        if (response.data.fusionData) {
          this.$store.commit('account_reorganisation/setOpenTargetRoleFusionData', response.data.fusionData);
          return response.data.fusionData;
        }
      } else {
        this.$store.commit('account_reorganisation/initTargetRole');
        return [];
      }
    });
  }

  fetchDocuments(data, show_loader) {
    return this.trigger('fusion/documents', data, show_loader).then(response => {
      if (response.data.success) {
        if (response.data.documentsData) {
          //always save base64 decoded content in store
          for (const [key, value] of Object.entries(response.data.documentsData)) {
            if (
              key.endsWith('_file_key')
              && typeof value === 'object'
              && typeof value['content'] === 'string'
              && value['content'] !== ''
            ) {
              try {
                response.data.documentsData[key]['content'] = window.atob(value['content']);
              // eslint-disable-next-line no-empty
              } catch (e) {}
            }
          }

          if (typeof data['fusionRole'] === 'string' && data['fusionRole'] === 'target') {
            this.$store.commit('account_reorganisation/setOpenTargetRoleFusionDocuments', response.data.documentsData);
          } else {
            this.$store.commit('account_reorganisation/setOpenSourceRoleFusionDocuments', response.data.documentsData);
          }

          return response.data.documentsData;
        }
      } else {
        return [];
      }
    });
  }

  downloadDocument(data) {
    return this.trigger('fusion/downloadDocument', data, true, '', 'blob')
      .then(response => {
        if (
          typeof response.headers !== 'undefined'
          && typeof response.headers['filename'] === 'string'
        ) {
          response['showSuccessMsg'] = 1;
        }
        this.trigger_download(response);
      });
  }

  abortAccountFusion(show_loader) {
    return this.trigger('fusion/abort', null, show_loader).then(response => {
      if (response.data.success) {
        return response;
      } else {
        if (
          typeof response.data !== 'undefined'
          && typeof response.data.message === 'string'
        ) {
          this.$toast.error(response.data.message);
        }
        return false;
      }
    });
  }

  clearAccountSessions(data, show_loader) {
    return this.trigger('fusion/clearAccountSessions', data, show_loader).then(response => {
      if (response.data.success) {
        return response;
      } else {
        if (
          typeof response.data !== 'undefined'
          && typeof response.data.message === 'string'
        ) {
          this.$toast.error(response.data.message);
        }
        return false;
      }
    });
  }

  setSessionExpired() {
    return this.trigger('fusion/setSessionExpired', {}, false).then(response => {
      return response;
    })
  }
}
