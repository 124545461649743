<template>
  <Dialog
    :modal="true"
    :visible.sync="isVisible"
    :contentStyle="{flex: '1', width: 'auto', height: 'auto'}"
  >
    <template #header>
      <h3 class="text-xl font-bold">
        <span>{{ $t('declarations.detail.appeal_type_selection.title') }}</span>
      </h3>
    </template>

    <div class="flex justify-between items-center mt-5">
      <div class="flex w-full justify-center space-x-4">
        <SelectButton
          v-model="selectedAppealType"
          :options="appealTypes"
          dataKey="code"
        >
          <template #option="slotProps">
            <img :src="slotProps.option.icon" class="image mr-2"/>
            <span>{{ slotProps.option.name }}</span>
          </template>
        </SelectButton>
      </div>
    </div>

    <div v-if="isMassAppealSelected">
      <div
        v-if="hasAccessToMassAppealFeature"
        class="flex flex-col h-full space-y-5"
      >
        <div class="flex justify-start items-center text-sm p-3 my-4 bg-gray_light rounded">
          <img
            class="image"
            :src="getAssetPath('info_gray.svg')"
          />
          <label class="text-left ml-3">
            <span v-html="$t('declarations.detail.appeal_type_selection.appeal_type_mass_info', {total_sum: massAppealPricePerMessage})"/>
          </label>
        </div>
        <div class="flex flex-row w-full space-x-5">
          <InputSelect
            class="w-full"
            v-model="selectedMassAppealTypeUid"
            :selectedValue="selectedMassAppealTypeUid"
            :label="$t('declarations.detail.appeal_type_selection.mass_appeal_types')"
            :options="massAppealTypesList"
            :isRequired="true"
            :errorMessage="$t('general.required')"
          />

          <InputDate
            class="w-full"
            v-model="massAppealActDate"
            :label="$t('declarations.detail.appeal_type_selection.mass_appeal_act_date')"
            appendTo="body"
            :isRequired="true"
            :minDateValueProp="new Date('2022-07-01')"
            :maxDateValueProp="new Date()"
            :errorMessage="$t('general.required')"
            :infoText="massAppealActDateInfoText"
          />
        </div>

        <div
          v-if="hasSelectedMassAppealType"
          class="flex flex-col"
        >
          <InputText
            class="w-full"
            v-model="selectedMassAppealType.justification"
            :label="$t('declarations.detail.elster_message.justification')"
            :isMultiline="true"
            :rows="25"
            :disabled="true"
            :infoText="$t('general.errors.administrative_act_hinweis_' + selectedMassAppealType.administrative_act)"
          />
        </div>
      </div>

      <div
        v-else
        class="flex flex-col w-full justify-center space-x-4"
      >
        <div class="w-full">
          <div class="flex w-fit justify-start items-center text-sm p-3 my-4 mx-auto bg-gray_light rounded">
            <img
              class="image"
              :src="getAssetPath('info_gray.svg')"
            />
            <label
              class="text-left ml-3"
              v-html="$t('declarations.detail.appeal_type_selection.feature_not_available')"
            />
          </div>
        </div>

        <div
          v-if="hasAccessToBilling"
          class="mt-8"
        >
          <p v-html="$t('declarations.detail.appeal_type_selection.feature_not_available_booking_info', {featureName: $t('billing_trial.feature_mass_ops')})"/>

          <Button
            class="items-center mt-8"
            :text="'Zur Paketübersicht wechseln'"
            @click="goToPlanSubscriptionPage"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-start items-center text-sm p-3 my-4 bg-gray_light rounded">
        <img
          class="image"
          :src="getAssetPath('info_gray.svg')"
        />
        <label
          class="text-left ml-3"
          v-html="$t('declarations.detail.appeal_type_selection.appeal_type_single_info')"
        />
      </div>
    </div>

    <template v-slot:footer>
      <div class="flex flex-row-reverse space-x-4">
        <Button
          class="w-44 ml-4"
          :text="$t('buttons.cancel')"
          :secondary="true"
          @click="close"
        />
        <Button
          class="w-44"
          :text="$t('buttons.save')"
          :disabled="!canSaveAppealType"
          @click="saveAppealType"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import { DeclarationService } from '@/services/declaration.service';
import { MassElsterMessageTypeService } from '@/services/MassElsterMessageType.service';

import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'AppealTypeSelectionModal',
  emits: ['close', 'savedAppealType'],
  components: {
    Dialog,
    SelectButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    declarationUid: {
      type: Number,
      default: 0
    },
    federalStateUid: {
      type: Number,
      default: 0
    },
    currentAppealType: {
      type: String,
      default: ''
    },
    currentMassAppealType: {
      type: Number,
      default: 0
    },
    currentMassAppealActDate: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      declarationService: new DeclarationService(),
      massElsterMessageTypeService: new MassElsterMessageTypeService(),

      selectedAppealType: null,
      appealTypes: [
        {
          icon: this.getAssetPath('appeal_type_single.svg'),
          name: this.$t('declarations.detail.appeal_type_selection.buttons.single'),
          code: 'single'
        },
        {
          icon: this.getAssetPath('appeal_type_mass.svg'),
          name: this.$t('declarations.detail.appeal_type_selection.buttons.mass'),
          code: 'mass'
        }
      ],

      selectedMassAppealTypeUid: 0,
      massAppealTypes: [],

      massAppealActDate: null,
      taxAssessmentDeadline: {}
    }
  },
  computed: {
    isVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        this.$emit('close', value);
      }
    },
    hasSelectedAppealType() {
      return this.selectedAppealType !== null;
    },
    isMassAppealSelected() {
      if (!this.hasSelectedAppealType) {
        return false;
      }

      if (this.selectedAppealType.code === 'mass') {
        return true;
      }

      return false;
    },
    hasAccessToMassAppealFeature() {
      if (this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_mass_appeal)) {
        return true;
      }

      return false;
    },
    hasAccessToBilling() {
      if (this.hasAccess('billing')) {
        return true;
      }

      return false;
    },
    massAppealPricePerMessage() {
      return parseFloat(this.getCurrentUser().plan_details.massAppealPrice);
    },
    massAppealTypesList() {
      let filteredMassAppealTypes = this.massAppealTypes;
      filteredMassAppealTypes = filteredMassAppealTypes.filter((o) => {
        if (o.administrative_act === '299' && ![2, 9].includes(parseInt(this.federalStateUid))) {
          return false;
        }
        if (o.administrative_act === '271' && [2, 7, 9].includes(parseInt(this.federalStateUid))) {
          return false;
        }
        if (o.federal_state_uids !== null && !o.federal_state_uids.includes(parseInt(this.federalStateUid))) {
          return false;
        }
        return true;
      });
      return filteredMassAppealTypes.map((massAppealType) => {
        return {
          code: massAppealType.prim_uid,
          name: massAppealType.name,
          administrativeAct: massAppealType.administrative_act
        };
      });
    },
    selectedMassAppealType() {
      let selectedMassAppealType = null;

      this.massAppealTypes.forEach((massAppealType) => {
        if (massAppealType.prim_uid === this.selectedMassAppealTypeUid) {
          selectedMassAppealType = massAppealType;
        }
      });

      return selectedMassAppealType;
    },
    taxAssessmentDeadlineDocumentDate() {
      return (this.taxAssessmentDeadline?.documentDate || '');
    },
    massAppealActDateEqualsTaxAssessmentDeadlineDocumentDate() {
      if (
        this.taxAssessmentDeadlineDocumentDate.length > 0
        && this.massAppealActDate?.length > 0
        && this.taxAssessmentDeadlineDocumentDate === this.massAppealActDate
      ) {
        return true;
      }

      return false;
    },
    massAppealActDateInfoText() {
      if (this.massAppealActDateEqualsTaxAssessmentDeadlineDocumentDate) {
        return this.$t('general.errors.transfer_from_the_deadline');
      }

      return '';
    },
    hasSelectedMassAppealType() {
      return this.selectedMassAppealType !== null;
    },
    hasSelectedMassAppealActDate() {
      if (this.massAppealActDate === null || this.massAppealActDate === '') {
        return false;
      }
      return true;
    },
    canSaveAppealType() {
      if (!this.hasSelectedAppealType) {
        return false;
      }

      if (this.selectedAppealType.code === 'single') {
        return true;
      }

      if (this.hasSelectedMassAppealType && this.hasSelectedMassAppealActDate) {
        return true;
      }

      return false;
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.show();
      }
    },
    selectedMassAppealTypeUid(newValue) {
      if (newValue === '') {
        this.selectedMassAppealTypeUid = 0;
        return;
      }

      if (this.selectedMassAppealTypeUid > 0) {
        if (this.massAppealActDateEqualsTaxAssessmentDeadlineDocumentDate) {
          this.massAppealActDate = '';
        }

        let massAppealType = this.massAppealTypesList.find(o => o.code === this.selectedMassAppealTypeUid);
        if (typeof massAppealType !== 'undefined' && massAppealType.administrativeAct !== '272') {
          this.$api.trigger('deadline/get_for_tax_assessment', {
            declarationUid: this.declarationUid,
            taxAssessmentSuffix: (massAppealType.administrativeAct === '108')
              ? 2
              : ((massAppealType.administrativeAct === '299') ? 3 : ''),
          }, true).then((response) => {
            if (response.data.success !== true) {
              this.taxAssessmentDeadline = {};
              return;
            }

            this.taxAssessmentDeadline = response.data.deadlineData;
            if (this.massAppealActDate === null || this.massAppealActDate.length === 0) {
              this.massAppealActDate = this.taxAssessmentDeadlineDocumentDate;
            }
          });
        }
      }
    },
  },
  methods: {
    ...mapGetters('user', ['getCurrentUser']),
    show() {
      let selectedAppealType = this.appealTypes[0];
      if (this.currentAppealType.length > 0) {
        this.appealTypes.forEach((appealType, index) => {
          if (appealType.code === this.currentAppealType) {
            selectedAppealType = this.appealTypes[index];
          }
        })
      }

      this.selectedAppealType = selectedAppealType;

      this.massElsterMessageTypeService.listAll('appeal', false).then((list) => {
        this.massAppealTypes = list;

        if (this.currentMassAppealType > 0) {
          this.selectedMassAppealTypeUid = this.currentMassAppealType;
        }
      });

      if (this.currentMassAppealActDate.length > 0) {
        this.massAppealActDate = this.currentMassAppealActDate;
      }
    },
    close() {
      this.isVisible = false;
      this.massAppealActDate = null;
      this.selectedMassAppealTypeUid = 0;
      this.taxAssessmentDeadline = {};
    },
    saveAppealType() {
      let massAppealActDate = this.massAppealActDate;
      let selectedMassAppealTypeUid = this.selectedMassAppealTypeUid;

      if (this.selectedAppealType.code === 'single') {
        massAppealActDate = null;
        selectedMassAppealTypeUid = null;
      }

      this.declarationService.saveAppealType({
        declarationUid: this.declarationUid,
        appealType: this.selectedAppealType.code,
        massAppealActDate: massAppealActDate,
        massAppealType: selectedMassAppealTypeUid
      }).then((success) => {
        if (success) {
          let saveMessageText = (this.selectedAppealType.code === 'single')
            ? this.$t('declarations.detail.appeal_type_selection.appeal_type_single_data_saved')
            : this.$t('declarations.detail.appeal_type_selection.appeal_type_mass_data_saved');

          this.$api.showToast(saveMessageText);

          this.$emit('savedAppealType');
          this.close();
        }
      });
    },
    goToPlanSubscriptionPage() {
      this.$router.push({
        name: 'Setting',
        params: {
          'comp': 'billing',
          'open_plan_subscription': true
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  height: 95vh;
  max-height: 95vh;
  max-width: 1240px;
  width: 80vw;

  .p-dialog-content {
    @include segoe-regular;

    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .p-dialog-footer {
    @apply p-3;
  }
}

:deep(.p-selectbutton) {
  .p-button.p-highlight {
    background-color: theme('colors.green');
    color: theme('colors.white');

    .image {
      filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(910%) hue-rotate(244deg) brightness(100%) contrast(100%);
    }
  }
}
</style>
