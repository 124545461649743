const state = {
  openSourceRoleFusionData: {},
  openSourceRoleFusionDocuments: {},
  openTargetRoleFusionData: {},
  openTargetRoleFusionDocuments: {}
};

const getters = {
  getOpenSourceRoleFusionData: (state) => state.openSourceRoleFusionData,
  getOpenSourceRoleFusionDocuments: (state) => state.openSourceRoleFusionDocuments,
  getOpenTargetRoleFusionData: (state) => state.openTargetRoleFusionData,
  getOpenTargetRoleFusionDocuments: (state) => state.openTargetRoleFusionDocuments
};

const mutations = {
  initSourceRole: (state) => {
    state.openSourceRoleFusionData = {
      prim_uid: 0,
      workflow: '',
      target_account_number: 0,
      generated_legitimation_file_key: '',
      clients_overview_file_key: '',
      uploaded_legitimation_file_key: '',
      identity_documents_file_key: '',
      verified: 0,
      verified_at: '',
      verified_by: 0,
      approved: 0,
      approved_at: '',
      approved_by: 0,
      started: 0,
      started_at: '',
      aborted: 0,
      aborted_at: '',
      created_at: '',
      created_by: 0
    };
    state.openSourceRoleFusionDocuments = {
      fusion_id: 0,
      generated_legitimation_file_key: {
        fileKey: '',
        fileName: '',
        content: '',
        mimeType: ''
      },
      clients_overview_file_key: {
        fileKey: '',
        fileName: '',
        content: '',
        mimeType: ''
      },
      uploaded_legitimation_file_key: {
        fileKey: '',
        fileName: '',
        content: '',
        mimeType: ''
      },
      identity_documents_file_key: {
        fileKey: '',
        fileName: '',
        content: '',
        mimeType: ''
      }
    }
  },
  setOpenSourceRoleFusionData: (state, fusionData) => {
    state.openSourceRoleFusionData = {...state.openSourceRoleFusionData, ...fusionData};
  },
  setOpenSourceRoleFusionDocuments: (state, documentsData) => {
    state.openSourceRoleFusionDocuments = {...state.openSourceRoleDocuments, ...documentsData};
  },
  initTargetRole: (state) => {
    state.openTargetRoleFusionData = {
      prim_uid: 0,
      workflow: '',
      target_account_number: 0,
      generated_legitimation_file_key: '',
      clients_overview_file_key: '',
      uploaded_legitimation_file_key: '',
      identity_documents_file_key: '',
      verified: 0,
      verified_at: '',
      verified_by: 0,
      approved: 0,
      approved_at: '',
      approved_by: 0,
      started: 0,
      started_at: '',
      aborted: 0,
      aborted_at: '',
      created_at: '',
      created_by: 0
    };
    state.openTargetRoleFusionDocuments = {
      fusion_id: 0,
      generated_legitimation_file_key: {
        fileKey: '',
        fileName: '',
        content: '',
        mimeType: ''
      },
      clients_overview_file_key: {
        fileKey: '',
        fileName: '',
        content: '',
        mimeType: ''
      },
      uploaded_legitimation_file_key: {
        fileKey: '',
        fileName: '',
        content: '',
        mimeType: ''
      },
      identity_documents_file_key: {
        fileKey: '',
        fileName: '',
        content: '',
        mimeType: ''
      }
    }
  },
  setOpenTargetRoleFusionData: (state, fusionData) => {
    state.openTargetRoleFusionData = {...state.openTargetRoleFusionData, ...fusionData};
  },
  setOpenTargetRoleFusionDocuments: (state, documentsData) => {
    state.openTargetRoleFusionDocuments = {...state.openTargetRoleDocuments, ...documentsData};
  }
};

const actions = {};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
