import {ApiService} from "@/services/api.service";

const state = {
  isAppModalVisible: false,
};

const getters = {
  getIsAppModalVisible: (state) => state.isAppModalVisible,
};

const mutations = {
  setIsAppModalVisible: (state, isAppModalVisible) => {
    state.isAppModalVisible = isAppModalVisible;
  },
};

const actions = {
  observeFusionRole({ commit, dispatch }, payload) {
    let doSetTimeout = !(
      typeof payload !== 'undefined'
      && typeof payload['doSetTimeout'] !== 'undefined'
      && payload['doSetTimeout'] === false
    );

    let api = new ApiService();
    api.trigger('get_fusion_role')
      .then((response) => {
        let hasActiveFusionRole = false;
        if (response.data.success) {
          if (typeof response.data.fusionRole === 'string') {
            commit('user/setFusionRole', response.data.fusionRole, { root: true });
            hasActiveFusionRole = ['source', 'target'].includes(response.data.fusionRole);
          }
        }

        if (hasActiveFusionRole) {
          if (doSetTimeout) {
            setTimeout(() => dispatch('observeFusionRole'), 1000 * 60 * 15);
          }
        }
      })
      .catch();
  },

  fetchFusionRole({ dispatch }) {
    dispatch('observeFusionRole', { doSetTimeout: false });
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
