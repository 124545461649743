<template>
  <div class="flex flex-col justify-start rectangle">
    <Header
      :title="$t('dashboard.widgets.blog.title')"
      :showDropDown="true"
      :showSetting="false"
      @popupclick="remove"
    />

    <div class="grid grid-cols-1 2xl:grid-cols-2 gap-3 p-4 overflow-y-auto">
      <div
        v-for="post in posts"
        class="flex flex-row w-full mb-6"
      >
        <div class="mr-4">
          <div class="w-32">
            <a
              :href="post.link"
              target="_blank"
            >
              <img
                class="rounded w-full h-full"
                :src="post.thumbnail_url"
                :alt="post.title"
                :title="post.title"
              />
            </a>
          </div>

          <div class="bg-gray_dark text-white text-sm mt-2 rounded">
            {{ post.posted_at }}
          </div>
        </div>

        <div class="flex flex-col text-left min-w-0">
          <a
            :href="post.link"
            target="_blank"
          >
            <div class="font-bold text-black mb-2 break-words hyphens-auto">
              {{ post.title }}
            </div>
          </a>

          <div class="text-muted_black text-sm tracking-wide break-words hyphens-auto">
            {{ post.description }}
          </div>

          <div class="mt-2">
            <a
              :href="post.link"
              target="_blank"
            >
              <span class="text-green underline font-semibold">
                {{ $t('dashboard.widgets.blog.read') }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <hr/>

    <div class="flex flex-col xl:flex-row text-black font-bold text-left justify-between items-center m-5">
      <div class="w-auto mb-4 xl:mr-2 xl:mb-0">
        {{ $t('dashboard.widgets.blog.follow_us') }}
      </div>

      <div class="w-fit flex flex-row gap-4 items-center">
        <a
          v-for="(socialMedia, index) in socialMediaList"
          :key="'social-media-' + index"
          :href="socialMedia.url"
          class="w-6"
          target="_blank"
        >
          <img
            :src="socialMedia.icon"
            :alt="socialMedia.name"
            :title="socialMedia.name"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogWidget',
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    widgetId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      posts: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: []
      },
      socialMediaList: [
        {
          name: 'Youtube',
          icon: this.getAssetPath('youtube.svg'),
          url: 'https://www.youtube.com/@finotaxtech',
        },
        {
          name: 'X (Twitter)',
          icon: this.getAssetPath('x_twitter.svg'),
          url: 'https://x.com/finotaxtech',
        },
        {
          name: 'Instagram',
          icon: this.getAssetPath('instagram.svg'),
          url: 'https://www.instagram.com/finotaxtech/',
        },
        {
          name: 'Facebook',
          icon: this.getAssetPath('facebook.svg'),
          url: 'https://www.facebook.com/finotaxtech',
        },
        {
          name: 'LinkedIn',
          icon: this.getAssetPath('linkedin.svg'),
          url: 'https://www.linkedin.com/company/finotaxtech/',
        },
      ]
    }
  },
  mounted() {
    this.$api.trigger('/blog/posts/').then((response) => {
      if (response.data.blogPosts) {
        this.posts = response.data.blogPosts;
      }
    });
  },
  methods: {
    remove() {
      this.$emit('click', 'removeWidget');
    }
  }
}
</script>

<style scoped lang="scss">
</style>
