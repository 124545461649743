<template>
  <div class="bg" :class="layout.length === 0 ? 'h-full' : ''" style="padding-top: 35px">
    <AccountFusionModal v-show="isSourceAccountFusionStandardModalVisible || isTargetAccountFusionModalVisible" />
    <AccountFusionAdministratorModal v-show="isSourceAccountFusionAdministratorModalVisible" />
    <PropertyTaxChangeNoticeModal
      :visible="isPropertyTaxChangeNoticeModalVisible"
      @close="markPropertyTaxChangeNoticeModalShown"
    />

    <!--  Dashboard Karten hinzu-->
    <ModalDialogRight v-show="isModalVisibledash" :canEscape="true" :canCloseOutsideClick="false"
                      @close="handleModaldashClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-6 ">
          <div class=" pr-8 mt-8"/>
          <div class="aside_hold ">
            <div class="w-full invisible"/>
            <label class="as_hd">{{ $t('dashboard.add_widgets_title') }}</label>
            <p class="as_p">{{ $t('dashboard.add_widget_help') }}</p>
            <ul class="aside_list">
              <li v-for="item in widgetsList" :key="item.i">
                <div class="hold_p" :class="item.disabled ? 'disabled' : ''">
                  <div class="as_img">
                    <img :src="getAssetPath(getWidgetThumb(item.i))">
                  </div>
                  <div class="as_content">
                    <label>{{ getWidgetTitle(item.i) }}</label>
                    <p>{{ getWidgetHelpInfo(item.i) }}</p>
                    <span class="icon_plus" @click="addWidget(item.i)">
                      <img src="@/assets/plus.svg">
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </ModalDialogRight>

    <!--  Dashboard Karten hinzu -->
    <!--  dialog chart setting-->
    <ModalDialog v-show="isModalVisible" @close="handleChartModalClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4">
          <label class="dheader text-left ml-6 mt-4">Einstellungen</label>
          <div class=" pr-8 mt-2 ml-2">
            <InputText label="Titel des Berichts " placeholder="Titel des Berichts " :isRequired="true"
                       :isBlackText="true"/>
            <InputSelect class="mt-6" label="Wählen Sie den Berichtstyp " placeholder="Wählen Sie den Berichtstyp"
                         :isRequired="true"
                         :isBlackText="true"/>
          </div>

          <div class="flex justify-around space-x-10 mt-4 mr-6 ">
            <div class="w-full invisible"/>
            <div class="flex mt-10 mb-10 justify-end space-x-4 ">
              <Button class="w-44 " @click="handleModalClose" :secondary="true" :text="$t('buttons.cancel') "></Button>
              <Button @click="handleModalClose" class=" w-44 mr-2 " :text="$t('buttons.save')"></Button>
            </div>

          </div>
        </div>
      </template>

    </ModalDialog>
    <!--  dialog trial expired-->
    <ModalDialog v-show="isTrialVisible" @close="handleModalClose" :showClose="false">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4">
          <div class="flex justify-center items-center">
            <img class="trial_img mt-20" :src="getAssetPath('trial.svg')"/>
          </div>
          <div class=" flex flex-col justify-center items-center ">
            <label class="trial_text mt-10">{{$t('dashboard.after_trial_end.title')}}</label>
            <label class="label mt-4 mr-28 ml-28">{{$t('dashboard.after_trial_end.tagline1')}}
              {{$t('dashboard.after_trial_end.tagline2')}}</label>


            <div class="flex mt-10 mb-10 justify-center   w-full">

              <Button @click="goToBilling" class=" label  bg-green mr-2  text-white" :text="$t('dashboard.after_trial_end.choose_package')"></Button>
              <Button  class=" label  bg-green mr-2  text-white" style="cursor:initial" v-if="is_trial_extended=='1'" :secondary="true" :text="$t('dashboard.after_trial_end.extend_trial')"></Button>
              <Button @click="extendTrial" class=" label  bg-green mr-2  text-white" v-else :text="$t('dashboard.after_trial_end.extend_trial')"></Button>
              <Button @click="logOut" class=" label  bg-green mr-2  text-white" :text="$t('dashboard.after_trial_end.de_register')"></Button>


            </div>
          </div>

        </div>
      </template>

    </ModalDialog>
    <!--      top banner-->
    <div class="dashboard-top-banner flex justify-start items-center  bg-white ml-6 mr-3 relative  justify-between">
      <div class="flex flex-col w-full space-y-6 header-bg">
        <p><span class="welcome w-full self-start">
           {{ $t('dashboard.top_header.welcome').replace('%userName', getCurrentUserName) }}
        </span></p>

        <!-- stats -->
        <Stats/>
      </div>
      <div class="top-account-id">{{ $t('sidebar.popup.user_id') }}: {{ getCurrentAccountId }}</div>
    </div>
    <hr class=" hr-line ml-7 mr-7 mb-3 mt-7" />
    <div class="ml-7 cursor-pointer add-cards flex" @click='isModalVisibledash=true'>
      <div class="mr-3 flex items-center">
        <img class="icon-add" src="@/assets/plus.svg"/>
      </div>
      <div class="icon-text flex-auto"> {{ $t('dashboard.top_header.add_cards') }}</div>
    </div>
    <div class="widget-container ml-1 mr-1 pb-7">
      <grid-layout v-if="layout.length > 0"
                   :layout.sync="layout"
                   :col-num="10"
                   :row-height="25"
                   :margin="[20,20]"
                   :is-draggable="draggable"
                   :is-resizable="true"
                   :vertical-compact="true"
                   :use-css-transforms="true"
                   @layout-updated="dashboardUpdated"
      >
        <grid-item v-for="(item, index) in layout"
                   :key="index"
                   :x="item.x"
                   :y="item.y"
                   :w="item.w"
                   :h="item.h"
                   :i="item.i"
                   :minW="item.minW"
                   :maxW="item.maxW ?? Infinity"
                   :minH="item.minH"
        >
          <component v-if="item.comp" :is="item.comp"
                     :config="getWidgetConfig(item.i)"
                     :widgetId="item.i"
                     @col-click="handleColClick"
                     @click="removeWidget(item.i)"
          />
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueGridLayout from "vue-grid-layout";
import NotificationWidget from "@/components/widgets/notification";
import ToDoWidget from "@/components/widgets/todo";
import ListWidget from "@/components/widgets/list"
import BlogWidget from "@/components/widgets/BlogPostsWidget.vue"
import PropertyTaxChangeNoticeModal from '@/components/Dashboard/PropertyTaxChangeNoticeModal';
import {widgetConfigs, widgets} from "@/core/constants/modules/widgets";
import {DashboardService} from "@/services/dashboard.service";
import {BillingService} from "@/services/billing.service";
import AccountFusionModal from "@/components/AccountReorganisation/AccountFusionModal.vue";
import AccountFusionAdministratorModal from "@/components/AccountReorganisation/AccountFusionAdministratorModal.vue";

export default {
  name: "Dashboard",
  components: {
    AccountFusionModal,
    AccountFusionAdministratorModal,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    NotificationWidget,
    ToDoWidget,
    ListWidget,
    BlogWidget,
    PropertyTaxChangeNoticeModal
  },
  metaInfo() {
    return {
      title: this.getTitle
    }

  },
  data() {
    return {
      service: new DashboardService(),
      billingService: new BillingService(),
      layout: [],
      initialized: false,
      draggable: true,
      resizable: true,
      renderedWidgets: [],
      index: 1,
      colNum: 12,

      isModalVisible: false,
      isTrialVisible: false,
      isModalVisibledash: false,
      isPropertyTaxChangeNoticeModalVisible: false,

      bigNav: true,
      notifications: [],
    }
  },
  computed: {
    ...mapGetters('user', ['getFusionRole']),
    getTitle() {
      return this.getTheme() === 'ebnerstolz' ? 'ES Grundsteuer - Dashboard' : 'GrundsteuerDigital - Dashboard'
    },
    customClasses() {
      return [{'nav-open': this.bigNav === true}, {'nav-close': this.bigNav === false}];
    },
    isSourceAccountFusionStandardModalVisible() {
      let fusionRole = this.getFusionRole;
      return (
        (typeof fusionRole === 'string')
        && (fusionRole === 'source')
        && (!['it_administrator', 'administrator'].includes(this.getCurrentUser().role))
        && (!this.getIsAppModalVisible())
      );
    },
    isSourceAccountFusionAdministratorModalVisible() {
      let fusionRole = this.getFusionRole;
      return (
        (typeof fusionRole === 'string')
        && (fusionRole === 'source')
        && (['it_administrator', 'administrator'].includes(this.getCurrentUser().role))
        && (!this.getIsAppModalVisible())
      );
    },
    isTargetAccountFusionModalVisible() {
      let fusionRole = this.getFusionRole;
      return (
        (typeof fusionRole === 'string')
        && (fusionRole === 'target')
        && (!this.getIsAppModalVisible())
      );
    },
    getCurrentUserName() {
      return this.getCurrentUser().name;
    },
    getCurrentAccountId() {
      return this.getCurrentUser().account_id;
    },
    is_trial_extended() {
      return this.getCurrentUser().is_trial_extended;
    },
    availableWidgets() {
      return widgets.filter((o) => {
        return !(this.$isBusiness && o.hideForBusiness);
      })
    },
    widgetsList() {
      let loadedWidgets = this.layout.map(item => item.i);
      let availableWidgets = widgets.filter((o) => {
        return !(this.$isBusiness && o.hideForBusiness);
      });
      return availableWidgets.map((o) => {
        o.disabled = loadedWidgets.includes(o.i);

        return o;
      });
    }
  },
  mounted() {
    this.chart()
    this.getDashboardLayouts()

    if (this.getCurrentUser().account_pay_type === 'trial') {
        var today = new Date();
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var dd = String(today.getDate()).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = dd + '.' + mm + '.' + yyyy;
        if (this.process(this.getCurrentUser().trial_end) <= this.process(today)){
            this.isTrialVisible = true
        }
    } else if (
      this.getCurrentUser().account_pay_type === 'trial_end'
      && this.getCurrentUser().afterEndTerm
      && this.getCurrentUser().afterEndTerm !== 'termination'
    ) {
      this.isTrialVisible = true;
    }

    this.isPropertyTaxChangeNoticeModalVisible = this.shouldShowPropertyTaxChangeNoticeModalVisible();
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser", "getCurrentTheme"]),
    ...mapGetters('global', ["getIsAppModalVisible"]),
    getTheme() {
      return this.getCurrentTheme()
    },
    shouldShowPropertyTaxChangeNoticeModalVisible() {
      if (document.cookie.split(';').some((item) => item.trim().startsWith('property-tax-change-notice-shown='))) {
        return false;
      }

      let currentDate = new Date().getTime();
      let showUntilDate = Date.parse('2024-04-02 23:59:59');
      if (currentDate > showUntilDate) {
        return false;
      }

      return true;
    },
    markPropertyTaxChangeNoticeModalShown() {
      document.cookie = 'property-tax-change-notice-shown=1; SameSite=Lax; expires=Fri, 31 Dec 9999 23:59:59 GMT';

      this.isPropertyTaxChangeNoticeModalVisible = this.shouldShowPropertyTaxChangeNoticeModalVisible();
    },
    process(date) {
      var parts = date.split(".");
      return new Date(parts[2], parts[1] - 1, parts[0]);
    },
    billingAvailable() {
      return !this.getCurrentUser().billing_by_partner;
    },
    getWidgetConfig(widgetId) {
      return widgetConfigs[widgetId] ? widgetConfigs[widgetId] : {};
    },
    getWidgetThumb(widgetId) {
      return widgetConfigs[widgetId]['thumb'] ? widgetConfigs[widgetId]['thumb'] : '';
    },
    getWidgetTitle(widgetId) {
      return widgetConfigs[widgetId]['menu'] ? widgetConfigs[widgetId]['menu'] : '';
    },
    getWidgetHelpInfo(widgetId) {
      return widgetConfigs[widgetId]['help'] ? widgetConfigs[widgetId]['help'] : '';
    },
    async dashboardUpdated(e) {
      // trigger save only on second call. as first time it will be called on dashboard load
      if (this.initialized) {
        if (await this.service.saveLayout(e)) {
          if (!this.isCustomDashboard()) {
            this.$api.get_user();
          }
        }
      } else {
        this.initialized = true;
      }
    },
    removeWidget(val) {
      this.initialized = true;
      const index = this.layout.map(item => item.i).indexOf(val);
      this.layout.splice(index, 1);

      if (this.layout.length === 0) {
        this.service.cleanDashboard()
      }
    },
    addWidget(i) {
      const index = this.layout.map(item => item.i).includes(i);
      if (!index) {

        let availableWidgets = widgets.filter((o) => {
          return !(this.$isBusiness && o.hideForBusiness);
        });

        let widget = availableWidgets.filter((o) => {
          if (o.i === i) {
            return true;
          }
        });

        if (widget.length > 0) {
          widget[0].y = this.layout.length + 10
          this.layout.push(widget[0]);
        }
        this.initialized = true;
        this.isModalVisibledash = false
      }
    },
    getDashboardLayouts() {
      if (this.isCustomDashboard()) {
        this.service.getLayouts().then((response) => {
          const layoutObj = [];
          let layouts = [];

          Object.keys(response).forEach(function (key) {
            let layout = JSON.parse(response[key]['layout']);

            if (typeof layouts[layout.i] == 'undefined') {
              layouts[layout.i] = layout;
              layoutObj.push(layout);
            }
          });

          this.layout = layoutObj;
        });
      } else {
        this.layout = this.availableWidgets
      }
    },
    isCustomDashboard() {
      let custom_dashboard = false;
      if (this.getCurrentUser().usersettings && this.getCurrentUser().usersettings.dashboard && this.getCurrentUser().usersettings.dashboard === 'custom') {
        custom_dashboard = true;
      }
      return custom_dashboard;
    },
    handleColClick(e) {
      if (e.widgetId === 'client-widget') {
        // Handle click event for client list widget
        if ((e.field === 'client_id' || e.field === 'client_name') && parseInt(e.row.prim_uid) > 0) {
          this.$router.push("/client/edit?uid=" + parseInt(e.row.prim_uid));
        }
      } else if (e.widgetId === 'view-log-widget' || e.widgetId === 'update-log-widget') {
        let recordUid = parseInt(e.row.recordUid);
        if (e.field === 'name' && recordUid > 0) {
          if (e.row.recordType === 'client') {
            this.$router.push('/client/edit?uid=' + recordUid);
          } else if (e.row.recordType === 'property') {
            this.$router.push('/property/edit?uid=' + recordUid);
          } else if (e.row.recordType === 'declaration') {
            this.$router.push('/declaration/edit?uid=' + recordUid);
          } else if (e.row.recordType === 'branch') {
            this.$router.push('/branch/edit?uid=' + recordUid);
          } else if (e.row.recordType === 'user') {
            this.$router.push('/user/edit?uid=' + recordUid);
          }
        }
      } else if (e.widgetId === 'deadline-widget') {
        if(e.field === 'declarationUid') {
          this.$router.push('/declaration/edit?uid=' + e.row.declarationId);
        } else if(e.field === 'clientName') {
          this.$router.push('/client/edit?uid=' + e.row.clientId);
        } else if(e.field === 'propertyName') {
          this.$router.push('/property/edit?uid=' + e.row.propertyId);
        }
      }
    },
    chart() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },

    handleToggle() {
      this.bigNav = !this.bigNav
      //alert('toggle')
    },
    toggle() {
      this.bigNav = !this.bigNav
    },
    handlePopUpClick() {
      this.isModalVisible = true
    },
    handleModalClose() {
      this.isTrialVisible = false
    },
    handleModaldashClose() {
      this.isModalVisibledash = false
    },
    handleChartModalClose() {
      this.isModalVisible = false
    },
    goToBilling() {
        if(!this.billingAvailable()) {
          return false;
        }
      this.$router.push('/setting/BillingTrial')
      this.isModalVisible = false
      this.isTrialVisible = false
      this.isModalVisibledash = false
    },
    handleCheckboxClick() {

      this.$router.push('/setting/CreateData')
    },
  extendTrial(){
      this.billingService.extendTrial().then((response) => {
          if (response.data.success) {
                this.$toast.success(this.$t('dashboard.after_trial_end.success_toast'));
          }
          this.isTrialVisible = false
          this.$api.get_user()
      });
  },
    logOut(){
        localStorage.removeItem('tc_logged_in');
        this.$api.trigger('logout', null , true).then(() => {
          location.href = '/';
        });
       }
  }
}
</script>

<style scoped lang="scss">

.hold_p {
  display: flex;
  text-align: left;
}


.as_content label {
  font-size: 14px;
  font-weight: 600 !important;
  color: theme('colors.muted_black');
}

ul.aside_list > li {
  border-bottom: 1px solid rgba(0, 0, 0, .08);
  padding: 20px 0 20px 0;
}

.as_content p {
  font-size: 14px;
  color: theme('colors.muted_black');
  //line-height: 1.6;
  // max-width: 172px;
  margin-top: 5px;
}

.as_img {
  max-width: 180px;
  width: 100%;
}

.disabled .as_img, .disabled .as_content {
  opacity: 0.5;

  .icon_plus {
    cursor: default;
  }
}

.as_hd {

  margin-bottom: 14px;
  display: block;

  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 25px;
}

.as_p {

  text-align: left;
  line-height: 1.4;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  width: 80%;
  font-size: 15px;
}

.as_content {
  padding: 0 100px 0 20px;
  position: relative;
  max-width: calc(100% - 100px);
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

span.icon_plus {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100px;
  height: 100%;
  top: 0;
  right: 0;
  justify-content: center;
  cursor: pointer;
}

span.icon_plus img {
  max-width: 20px;
}

.charts {
  width: 90%;
  position: relative;


  height: 561px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.scroll-height {
  height: 375px;
}

.scroll-height-table {
  height: 450px;
}

.nav-open {
  width: 300px;
  animation: slide-right .2s;
}

.nav-close {
  width: 56px;
  animation: slide-left .2s reverse;
}

@-webkit-keyframes slide-left {
  from {
    width: 56px;
  }

  to {

    width: 300px;
  }
}

@-webkit-keyframes slide-right {
  from {
    width: 56px;
  }

  to {

    width: 300px;
  }
}

.bold-header {
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 25px;
}

.add-cards {
  height: 25px;
  margin-top: 10px;
  width: fit-content;
}

.icon-add {
  width: 18px;
  height: 18px;
}

.icon-text {
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;

}

.rectangle-chart {
  width: 95%;
  height: 100%;
  margin-top: 20px;
  margin-right: 50px;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
}

.rectangle {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #d1d1d1;
  text-align: center;
  line-height: normal;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  border: 1px solid #d7d7d7;
}

.not {
  height: 448px;
  max-height: 561px;
}

::-webkit-scrollbar:horizontal {
  display: none;
}

.welcome {
  font-size: 26px;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  margin-left: 10px;
  padding-left: 20px;
  float: left;
}


canvas {
  height: 100px;
}

.image {
  height: 105px;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

label.dheader {
  position: relative;
  top: -35px;
  left: -20px;
  //padding: 0 20px;
  max-width: 50%;

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

.trial_img {
  width: 141px;
  height: 103px;
  box-sizing: border-box;
}

.trial_text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;

}

th {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
}

.icon-table {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.green_text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: #229d56;
  text-align: left;
  font-size: 15px;
}

.bg {
  background-color: #f4f8f9;
}

.scroll-height-table {
  height: 450px;
}

.icontable {
  margin-right: 20px;
  width: 16px;
  height: 16px;
  color: #333333;
}

.empty {
  width: 70px;
}

.empty-text {
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 25px;
}

.empty-subtext {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  font-size: 15px;
}

.empty-chart {
  width: 160px;
  height: 160px;
  opacity: 0.18;
}

.empty-text-chart {
  font-size: 14px;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
}

::v-deep .log-type-icon {
  width: 100px;
}

.widget-container ::v-deep .table-container tr.p-datatable-emptymessage td {
  padding-left: 32px !important;
}
.top-account-id {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1rem;
  color: #aeaeae;
}
</style>
