<template>
  <Dialog
    :modal="true"
    :closable="false"
    :closeOnEscape="false"
    :visible.sync="isVisible"
    :contentStyle="{flex: '1'}"
    :baseZIndex="11000"
  >
    <AccountFusion ref="fusion" />
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import AccountFusion from "@/components/AccountReorganisation/AccountFusion.vue";
import { appMixin } from "@/core/utils/mixin";

export default {
  name: 'AccountFusionAdministratorModal',
  components: {
    Dialog,
    AccountFusion
  },
  mixins: [appMixin],
  data() {
    return {
      isVisible: true
    }
  },
  mounted() {
    this.$refs['fusion'].activateStep('manage_documents');
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  width: 95vw;
  height: fit-content;
  max-height: 95vh;

  .p-dialog-header {
    display: flex;
    justify-content: center;
  }
}
</style>
