import Vue from 'vue'
import App from './App.vue'
import "./vue-plugins"
import router from './router'
import store from './store'
import "./vue-components";
import {routesWoutSessionCheck} from "@/core/constants";
import {datadogLogs} from "@datadog/browser-logs";
import {i18n} from '@/plugins/i18n';
import {appMixin} from "./core/utils/mixin";
import {ApiService} from "./services/api.service";
//import {VueReCaptcha} from "vue-recaptcha-v3";
import {isNull} from "lodash";
import './assets/tailwind.css';

Vue.config.productionTip = false

Vue.prototype.$api = new ApiService();
Vue.prototype.$isBusiness = process.env.VUE_APP_DEV_VARIANT === 'business'
Vue.prototype.$inDevelopment = process.env.NODE_ENV !== 'production'
Vue.mixin(appMixin);

/*Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY
});*/

if(process.env.VUE_APP_DATADOG_TOKEN && process.env.VUE_APP_DATADOG_SITE) {
  datadogLogs.init({
    clientToken: process.env.VUE_APP_DATADOG_TOKEN,
    site: process.env.VUE_APP_DATADOG_SITE,
    forwardErrorsToLogs: true,
    service: process.env.VUE_APP_DATADOG_SERVICE,
    beforeSend: (log) => {
      // don't log client network failures and request cancellations
      if (
        typeof log.error !== 'undefined'
        && typeof log.http !== 'undefined'
        && typeof log.http.status_code !== 'undefined'
        && log.http.status_code === 0
      ) {
        return false;
      }

      if (
        log.message.indexOf('[Vue warn]') !== -1
        || log.message.indexOf('props.column.$scopedSlots') !== -1
        || log.message.indexOf('Avoided redundant navigation') !== -1
      ) {
        return false;
      }

      if (store.getters["user/getCurrentUser"].account_uid) {
        log.tags.push('accountid:' + store.getters["user/getCurrentUser"].account_uid);
      }

      if (store.getters["user/getCurrentUser"].id) {
        log.tags.push('userid:' + store.getters["user/getCurrentUser"].id);
      }

      return true;
    }
  })
  datadogLogs.setGlobalContext({
    host: location.host,
    tags: []
  })
}

router.beforeEach((to, from, next) => {
  if(!routesWoutSessionCheck.includes(to.name) && !isNull(store.state.user.authorized) && !store.state.user.authorized) {
    next("/");
  } else {
    if (['source', 'target'].includes(store.getters['user/getFusionRole'])) {
      if (to.path === '/dashboard') {
        next();
      } else {
        next("/dashboard");
      }
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  i18n,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
