<template>
  <div>
    <div v-show="!hasStarted()">
      <div class="flex flex-row justify-between">
        <SelectButton
          v-model="selectedReorgOption"
          :options="reorgOptions"
          dataKey="value"
          optionDisabled="disabled"
          @input="performSelection"
        >
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template #option="slotProps">
            <div class="p-10" :class="slotProps.option.disabled ? 'bg-gray' : ''">
              <img
                :src="getAssetPath('circular-check.svg')"
                class="checkmark absolute top-4 left-4"
              />

              <img
                :src="slotProps.option.icon"
                class="w-20 mx-auto"
              />

              <h2 class="text-lg font-semibold my-7">{{ slotProps.option.headline }}</h2>

              <p class="mb-4">{{ slotProps.option.section1 }}</p>
              <p>{{ slotProps.option.section2 }}</p>
            </div>
          </template>
        </SelectButton>
      </div>

      <div class="tab-footer mr-6">
        <hr class="hr-line-user mt-25">
        <div class="flex justify-end mt-5 space-x-6">
          <Button
            v-show="!hasSelection()"
            class="button-label text-center text-white"
            secondary
            :text="$t('buttons.next')"
          />
          <Button
            v-show="hasSelection()"
            @click="continueProcess()"
            class="button-label text-center bg-green text-white"
            primary
            :text="$t('buttons.next')"
          />
        </div>
      </div>
    </div>

    <div v-show="hasStarted()">
      <AccountFusion
        v-show="startedReorgOption !== null && startedReorgOption.value === 'fusion'"
        ref="fusion"
        @cancel_workflow="cancelWorkflow()"
      />
    </div>
  </div>
</template>

<script>
import SelectButton from 'primevue/selectbutton';
import AccountFusion from '@/components/AccountReorganisation/AccountFusion.vue';

export default {
  name: 'ReorgStart',
  components: {
    SelectButton,
    AccountFusion
  },
  props: {
    maxReorgOptions: {
      type: Number,
      default: 2
    },
    preSelectionValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedReorgOption: null,
      oldSelectedReorgOption: null,
      startedReorgOption: null,
      reorgOptions: [],
    }
  },
  beforeMount() {
    this.reorgOptions = [];
    if (this.maxReorgOptions > 0) {
      this.reorgOptions.push({
        icon: this.getAssetPath('chain.svg'),
        headline: this.$t('settings.taxCategories.reorg.fusion_selector.headline'),
        section1: this.$t('settings.taxCategories.reorg.fusion_selector.section1'),
        section2: this.$t('settings.taxCategories.reorg.fusion_selector.section2'),
        value: 'fusion',
        disabled: false
      });

      if (this.maxReorgOptions > 1) {
        this.reorgOptions.push({
          icon: this.getAssetPath('broken_link.svg'),
          headline: this.$t('settings.taxCategories.reorg.separation_selector.headline'),
          section1: this.$t('settings.taxCategories.reorg.separation_selector.section1'),
          section2: this.$t('settings.taxCategories.reorg.separation_selector.section2'),
          value: 'separation',
          disabled: true
        });
      }
    }

    if (this.preSelectionValue !== '') {
      let preSelectedOption = this.reorgOptions.find(
        o => o.value === this.preSelectionValue,
        this
      );

      if (preSelectedOption && !preSelectedOption.disabled) {
        this.selectedReorgOption = preSelectedOption;
      }
    }
  },
  watch: {
    selectedReorgOption(newValue) {
      this.oldSelectedReorgOption = newValue;
    }
  },
  methods: {
    hasSelection() {
      return this.selectedReorgOption !== null;
    },
    hasStarted() {
      return this.startedReorgOption !== null;
    },
    resetSelection() {
      this.selectedReorgOption = null;
      this.oldSelectedReorgOption = null;
      this.startedReorgOption = null;
    },
    performSelection() {
      if (
        (this.selectedReorgOption !== null)
        && (this.oldSelectedReorgOption !== null)
        && (this.selectedReorgOption.value === this.oldSelectedReorgOption.value)
      ) {
        this.resetSelection();
      }
    },
    continueProcess() {
      if (
        this.hasSelection()
        && this.selectedReorgOption.disabled !== true
        && this.selectedReorgOption.value !== ''
      ) {
        this.startedReorgOption = this.selectedReorgOption;
        if (typeof this.$refs[this.startedReorgOption.value] !== 'undefined') {
          let reorgInstance = this.$refs[this.startedReorgOption.value];
          let processMethod = reorgInstance.process;
          if (typeof processMethod !== 'undefined') {
            processMethod();
          }
        }
      }
    },
    cancelWorkflow() {
      this.startedReorgOption = null;
      this.selectedReorgOption = null;
      this.oldSelectedReorgOption = null;
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.p-selectbutton) {
  img.checkmark {
    opacity: 0;
  }

  .p-button {
    @apply border-2 rounded m-5;
    background: theme('colors.white');
    box-shadow: none;

    &.p-component {
      @apply w-5/12;

      &:hover {
        background: #ffffff;
      }
    }

    &.p-highlight {
      border-color: theme('colors.green');

      img.checkmark {
        opacity: 100%;
      }
    }
  }
}
</style>
