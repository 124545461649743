<template>
  <div class=" flex flex-col ">
    <div class="flex">
      <!-- Label with required indicator -->
      <div :class="CustomClasses" class="label">{{ label }}</div>
      <span v-if='isRequired' style="color:red">*</span>
      <!--      help icon with Text tooltip-->
      <img v-if="showHelpIcon" class="help_icon" :src="getAssetPath('help_green.svg')" v-tooltip.right="tooltip"/>
      <!--      help icon with HTML tooltip/popover-->

      <VDropdown v-if="showHelpIconPopover" :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                 :hideTriggers="['click']">
        <!-- This will be the popover target (for the events and position) -->
        <img class="help self-center  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
        <!-- This will be the content of the popover -->
        <template #popper>
          <div class="tooltip-content">
            <div class="space-y-4">
              <p class=" popover-text" v-for="text in popoverTexts" v-html="text" v-bind:key="text">
              </p>
            </div>
            <h2 style="line-height:80%; margin-top: 5px;"><a class="popover-text" :href="popoverLink"
                                                             target="_blank">{{ popoverLink }}</a></h2>
          </div>
          <!-- You can put other components too -->
        </template>
      </VDropdown>
    </div>
    <!-- input View -->
    <div :class="CustomClasses"
         class="input-box mt-1 flex">
      <Password ref="pwdEl" v-model="field" :prompt-label="$t('register.step4.passwordTemplate.promptLabel')"
                append-to="body"
                v-if="showPasswordTemplate" :placeholder="placeholder" :strong-regex="strongRegex">
        <template #header>
          <h6>{{ $t('register.step4.passwordTemplate.title') }}</h6>
        </template>
        <template #footer>
          <Divider/>
          <div class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
            <div class="flex justify-start items-center ">
              <img class="validation-img" :src="getMinimumValidationImage(field)"/>
              <label :style="{'color': minimumError ? '#229D56': '#A4262C'}">
                {{ $t('register.step4.passwordTemplate.text0') }}
              </label>
            </div>
            <div class="flex justify-start items-center ">
              <img class="validation-img" :src="getLowercaseValidationImage(field)"/>
              <label :style="{'color': lowercaseError ? '#229D56': '#A4262C'}">
                {{ $t('register.step4.passwordTemplate.text1') }}
              </label>
            </div>
            <div class="flex justify-start items-center ">
              <img class="validation-img" :src="getUppercaseValidationImage(field)"/>
              <label :style="{'color': uppercaseError ? '#229D56': '#A4262C'}">
                {{ $t('register.step4.passwordTemplate.text2') }}
              </label>
            </div>
            <div class="flex justify-start items-center ">
              <img class="validation-img" :src="getNumericValidationImage(field)"/>
              <label :style="{'color': numericError ? '#229D56': '#A4262C'}">
                {{ $t('register.step4.passwordTemplate.text3') }}
              </label>
            </div>
            <div class="flex justify-start items-center ">
              <img class="validation-img" :src="getDifferentCharValidationImage(field)"/>
              <label :style="{'color': threeCharError ? '#229D56': '#A4262C'}">
                {{ $t('register.step4.passwordTemplate.text4') }}
              </label>
            </div>
            <div class="flex justify-start items-center ">
              <img class="validation-img" :src="getDifferentUserNameValidationImage(field)"/>
              <label :style="{'color': usernameError ? '#229D56': '#a72121'}">
                {{ $t('register.step4.passwordTemplate.text5') }}
              </label>
            </div>
          </div>
        </template>
      </Password>
      <div
        v-show="!showPasswordTemplate"
        v-if="!isMultiline"
        class="flex items-center w-full"
      >
        <span v-if="prefix !== ''" class="prefix px-2">{{ prefix }}</span>
        <input
          ref='input'
          :type="inputType"
          v-model="field"
          class="focus:outline-none focus:ring-offset-1 px-2"
          :class="bodyClass + ' ' + hasPrefix"
          :style="{'color': disabled ? '#aeaeae': '#4b4b4b'}"
          :disabled="disabled"
          :maxlength="fieldMaxLimit"
          :name="elName"
          :id="elName"
          :autocomplete="autocomplete ? 'on' : 'off'"
          @keyup.enter="handleSubmit"
          @keypress="onKeyPress"
          @mouseup="onKeyUp"
          @keyup="onKeyUp"
          @blur="handleBlur"
          @focus="handleFocus"
          :placeholder="placeholder"
        />
      </div>
      <textarea :type="inputType" :rows="rows" :cols="cols" v-model="field"
                class="focus:outline-none focus:ring-offset-1  "
                :class="bodyClass"
                :disabled="disabled"
                style="width:100%;padding: 5px 10px;color:#4b4b4b"
                @keydown="onTextareaKeyDown"
                @keyup.enter="handleSubmit"
                :placeholder="placeholder" v-else/>
      <!--        right side text-->
      <p v-if="rightText!==''" class="right-text text-center self-end">{{ rightText }}</p>
      <i class="pi pi-spin pi-spinner" v-if="loading"/>
      <IconLabel v-if="showPasswordViewIcon" class="-mt-1 eyeIcon" :icon="eyeIcon" :blackText="true"
                 @click="showPassword"/>
    </div>
    <!-- Infotext label -->
    <label
        v-show="!disabled && infoText !== ''"
        class="info-label self-start mt-1"
    >
      {{ infoText }}
    </label>
    <!--      Error Message label-->
    <label
        v-show="!disabled && errorMessageText !== ''"
        class="error-label self-start mt-1"
        :class="errorMessageClass"
    >
      {{ errorMessageText }}
    </label>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import Password from 'primevue/password';
import Divider from 'primevue/divider';
import {mapGetters} from "vuex";
import {uniqueId} from "lodash/util";

export default {
  name: "InputText",
  components: {Password, Divider},
  directives: {
    'tooltip': Tooltip,
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  props: {
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 255
    },
    errorMessage: {
      type: String,
      default: '',
    },
    infoText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isOnlyNumber: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isOnlyPositive: {
      type: Boolean,
      default: false
    },
    allowLeadingZero: {
      type: Boolean,
      default: false
    },
    isFloat: {
      type: Boolean,
      default: false
    },
    isZip: {
      type: Boolean,
      default: false
    },
    zipCountry: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: 'DE',
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    showHelpIcon: {
      type: Boolean,
      default: false
    },
    showHelpIconPopover: {
      type: Boolean,
      default: false
    },
    popoverTexts: {
      type: Array,
      default: () => []
    },
    popoverLink: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: 'No tooltip provided'
    },
    isMultiline: {
      type: Boolean,
      default: false
    },
    maxLimit: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 0
    },
    requestFocus: {
      type: Boolean,
      default: false
    },
    bodyClass: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 5
    },
    cols: {
      type: Number,
      default: 60
    },
    loading: {
      type: Boolean,
      default: false
    },
    showPasswordTemplate: {
      type: Boolean,
      default: false
    },
    showPasswordViewIcon: {
      type: Boolean,
      default: false
    },
    decimalNumber: {
      type: Number,
      default: 2
    },
    isRegex: {
      type: String,
      default: ''
    },
    isEmail: {
      type: Boolean,
      default: false
    },
    isAlphaNumric: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputType: 'text',
      eyeIcon: this.getAssetPath('eye_green.svg'),
      strongRegex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{9,})',
      field: '',
      maskedField: '',
      elName: '',
      error: false,
      lowercaseError: false,
      uppercaseError: false,
      numericError: false,
      minimumError: false,
      threeCharError: false,
      usernameError: false,
      errorShow: false,
      errorMessageText: '',
    };
  },
  computed: {
    ...mapGetters('register', ['getUserData']),
    CustomClasses() {
      return [
        {'multiline': this.isMultiline},
        {'error': this.errorShow},
        {'disabled': this.disabled},
        {'input_loader': this.loading},
      ]
    },
    errorMessageClass() {
      let errorMessageClass = '';
      if (this.maxLimit > 0) {
        errorMessageClass += 'visible';
        if (this.errorShow === true || this.value.length > this.maxLimit) {
          errorMessageClass += ' red-color';
        } else {
          errorMessageClass += '';
        }
      } else {
        errorMessageClass += ' red-color';
        if (this.errorShow === true) {
          errorMessageClass += ' visible';
        } else {
          errorMessageClass += ' hidden';
        }
      }
      return errorMessageClass;
    },
    isDomesticCountry() {
      return (
        parseInt(this.zipCountry) === 50
        || parseInt(this.zipCountry) === 0
        || this.zipCountry === 'DE'
        || this.zipCountry === ''
      );
    },
    fieldMaxLimit() {
      if (this.isZip) {
        return this.isDomesticCountry ? 5 : 12;
      }

      if (this.maxLimit > 0) {
        return this.maxLimit;
      }

      // Use 64kb max length for multiline text fields without explicit limit
      if (this.isMultiline && this.maxLimit === 0 && this.maxLength === 255) {
        return 65535;
      }

      return this.maxLength;
    },
    hasPrefix() {
      return this.prefix === '' ? '' : 'has-prefix';
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.field = this.value;

        this.filterNotAllowedCharsFromInput();
      }
    },
    field(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.isRequired) {
          this.errorShow = newValue === '';
        }

        this.$emit('input', newValue);
      }
    },
    isError(newValue) {
      this.errorShow = newValue;
    },
    requestFocus(newValue) {
      if (newValue) {
        this.$refs.input.focus();
        this.$refs.input.select();
      }
    },
    errorMessage(newValue) {
      this.errorMessageText = newValue;
    }
  },
  created() {
    if (this.elName === '') {
      this.elName = uniqueId('grd-el-');
    }
  },
  mounted() {
    this.inputType = this.type;
    this.field = this.value;
    this.errorShow = this.isError
    this.maskedField = this.field;
    this.errorMessageText = this.errorMessage;

    if (this.requestFocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    setFocus() {
      this.$refs.input.focus();
      this.$refs.input.select();
    },
    showPassword() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
        this.eyeIcon = this.getAssetPath('eye_green-cut.svg');
      } else {
        this.inputType = 'password';
        this.eyeIcon = this.getAssetPath('eye_green.svg')
      }

      if (this.showPasswordTemplate) {
        this.$refs.pwdEl.onMaskToggle();
      }
    },
    getLowercaseValidationImage(field) {
      if (/[a-z]/.test(field)) {
        this.lowercaseError = true;
        return this.getAssetPath('tick_password.svg');
      }

      this.lowercaseError = false;
      return this.getAssetPath('cross_password.svg');
    },
    getUppercaseValidationImage(field) {
      if (/[A-Z]/.test(field)) {
        this.uppercaseError = true;
        return this.getAssetPath('tick_password.svg');
      }

      this.uppercaseError = false;
      return this.getAssetPath('cross_password.svg');
    },
    getMinimumValidationImage(field) {
      if (field.length >= 9) {
        this.minimumError = true;
        return this.getAssetPath('tick_password.svg');
      }

      this.minimumError = false;
      return this.getAssetPath('cross_password.svg');
    },
    getNumericValidationImage(field) {
      if (/\d/.test(field)) {
        this.numericError = true;
        return this.getAssetPath('tick_password.svg');
      }

      this.numericError = false;
      return this.getAssetPath('cross_password.svg');
    },
    getDifferentCharValidationImage(field) {
      let charArray = field.split('');
      let uniqueChar = charArray.filter((item, i, ar) => ar.indexOf(item) === i);

      if (uniqueChar.length >= 3) {
        this.threeCharError = true;
        return this.getAssetPath('tick_password.svg');
      }

      this.threeCharError = false;
      return this.getAssetPath('cross_password.svg');
    },
    getDifferentUserNameValidationImage(field) {
      if (this.getUserData.email !== field) {
        this.usernameError = true;
        return this.getAssetPath('tick_password.svg');
      }

      this.usernameError = false;
      return this.getAssetPath('cross_password.svg');
    },
    onKeyPress() {
      this.$emit('keypress');
    },
    onTextareaKeyDown() {
      this.$emit('keyup');
    },
    onKeyUp() {
      if (this.field === 'NaN') {
        this.errorMessageText = this.$t('general.errors.invalid_pasted_text');
        this.errorShow = true;
        this.field = '';
      } else {
        this.errorShow = false;
      }
    },
    handleFocus() {
      this.errorShow = false;
    },
    handleBlur() {
      if (this.isRequired) {
        this.errorShow = this.field === '' || (this.isEmail === true && !this.validateEmail(this.field));
      } else if (this.isEmail) {
        this.errorShow = !this.validateEmail(this.field);
      }
      this.$emit('blur')
    },
    handleSubmit() {
      this.$emit('keyup')
    },
    validateEmail(email) {
      // eslint-disable-next-line
      const reg = /^[A-Za-z\d\.\_\%\+\-]+@([A-Za-z\d-]+\.)+[A-Za-z]{2,7}$/i;
      return reg.test(email.trim());
    },
    filterNotAllowedCharsFromInput() {
      if (
        typeof this.field === 'undefined'
        || this.field === null
        || this.field.toString().trim() === ''
      ) {
        return;
      }

      // Clean for isOnlyNumber
      if (this.isOnlyNumber === true || this.inputType === 'number') {
        const regexIsNotNumber = this.isOnlyPositive ? /[^\d]/mg : /(?<!^)[-]|[^\d-]/mg;
        this.field = this.field.toString().replace(regexIsNotNumber, '');
      }

      // Clean leading zeros if disallowed
      if (this.isOnlyPositive && !this.allowLeadingZero) {
        const regexLeadingZeroes = /^0+(?=\d)/mg;
        this.field = this.field.toString().replace(regexLeadingZeroes, '');
      }

      // Clean for isAlphaNumeric
      if (this.isAlphaNumric === true) {
        const regexIsNotAlphaNumric =  /[^A-Za-z0-9]/mg;
        this.field = this.field.toString().replace(regexIsNotAlphaNumric, '');
      }

      // Clean for isFloat
      if (this.isFloat) {
        const regexIsNotOnlyFloat = /[^\d.,]/mg;
        this.field = this.field.toString().replace(regexIsNotOnlyFloat, '');

        if (this.$store.state.user.currentUser.lang_code === 'en_us') {
          this.field = parseFloat(this.field).toString();
        }

        // Restrict floats to max amount of decimals
        if (this.decimalNumber) {
          const regexFirstFloatNumber = new RegExp('(\\d+\\.\\d{0,' + this.decimalNumber + '})|(\\d+\\,\\d{0,' + this.decimalNumber + '})', '');
          let firstFloatNumber = this.field.toString().match(regexFirstFloatNumber);

          if (firstFloatNumber !== null) {
            this.field = firstFloatNumber[0];
          }
        }
      }

      // Clean for isZip
      if (this.isZip) {
        const regexZip = this.isDomesticCountry ? /[^0-9]+/mg : /[^A-Za-z0-9 -]+/mg;
        this.field = this.field.toString().replace(regexZip, '');
      }

      // Restrict numbers to max value
      if (
        this.maxValue
        && (this.isFloat || this.isOnlyNumber || this.inputType === 'number')
      ) {
        let normalizedValue = this.field;
        if (this.$store.state.user.currentUser.lang_code === 'en_us') {
          normalizedValue = normalizedValue.toString().replace(',', '');
        } else {
          normalizedValue = normalizedValue.toString().replace('.', '').replace(',', '.');
        }

        normalizedValue = parseFloat(normalizedValue);

        if (normalizedValue > this.maxValue) {
          normalizedValue = this.maxValue;

          if (this.$store.state.user.currentUser.lang_code !== 'en_us') {
            normalizedValue = normalizedValue.toString().replace('.', ',');
          }

          this.field = normalizedValue;
        }
      }

      // Restrict to provided value from isRegex
      if (this.isRegex !== '') {
        let regexMatches = this.field.toString().match(this.isRegex);
        this.field = (regexMatches !== null && regexMatches[0] !== null) ? regexMatches[0] : '';
      }

      // Restrict max length of field
      if (this.fieldMaxLimit > 0 && this.field.length > this.fieldMaxLimit) {
        this.field = this.field.toString().substring(0, this.fieldMaxLimit);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.right-text {
  margin-bottom: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: $primary;
  text-align: right;
  line-height: normal;

  margin-right: 5px;
  font-size: 15px;
}

div.input-box:focus-within {
  border: 1px solid $primary;
}

.invite_email_body .multiline {
  height: auto !important;
}

.input-box {
  font-family: "Segoe UI", "Segoe UI Regular", sans-serif;
  font-weight: 400;
  width: 100%;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  border: 1px solid $border-color;
  background-color: #ffffff;
  box-sizing: border-box;
  color: black;
  text-align: left;
  border-radius:4px;

  &.multiline {
    min-height: 120px;
    display: inline-table;

    textarea {
      min-height: 100%;
    }
  }

  &:focus {
    border: 1px solid $primary;
  }

  &.error {
    border: 1px solid $error-color;

  }

  &.disabled {
    background-color: #f4f1f3;
    color: #aeaeae;
    border: 0 solid #d1d1d1;
  }
}

input {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border-radius:4px;

  &.disabled {
    background-color: #f4f1f3;
    color: #aeaeae;
  }
}

.label {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;

  &.disabled {
    color: #797979;
  }
}

.error-label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  float: left;
}

.info-label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  float: left;
  background-color: #f2f2f2;
  color: #333333;
  text-align: left;
  line-height: normal;
  padding: 8px;
}

.input_loader {
  position: relative;

  i.pi-spinner {
    position: absolute;
    right: 5px;
    top: 6px;
  }

  input {
    padding-right: 30px;
  }
}

.validation-img {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

a {
  color: white;
  text-decoration: underline;
}

.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.red-color {
  color: #a4262c
}
.popover-text ::v-deep ul {
  list-style-type: disc;
  margin-left: 12px;
}
.prefix {
  position: relative;
  color: grey;
}
input.has-prefix {
  padding-left: 30px;
  margin-left: -30px
}

</style>
