<template>
  <div class="button-wrapper flex flex-col">
    <div :class="CustomClasses" class=" button  flex flex-col justify-center items-center" @click="handleClick">
      <img
        class="img"
        :class="[
          {'mr-2': text.length > 0},
          {'m-1': text.length === 0}
        ]"
        :src="icon"
        v-if="icon"
      />

      <div class="flex flex-row items-center">
        <i class="pi pi-spin pi-spinner mr-4" v-show="loading"/>
        <p v-if="!withTooltipText" :class="CustomClasses" class="button-text self-center">{{ text }}</p>
        <span v-if="withTooltipText" class="cursor-pointer icon-container">
          <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']" :hideTriggers="['click']" class="inline">
            <p :class="CustomClasses" class="button-text self-center">{{ text }}</p>
            <template #popper>
              <div class="tooltip-content">
                <div class="space-y-4">
                  <p class=" popover-text">{{ tooltipText }}</p>
                </div>
              </div>
            </template>
          </VDropdown>
        </span>
      </div>
      <img class="img-right" :src="rightIcon" v-if="rightIcon"/>
    </div>
    <label class="button_bottom_text" v-if="subText!==''">{{ subText }}</label>
    <label class="button_bottom_text" v-if="elsterAutoSubmissionApproved">
      {{ $t('declarations.detail.buttons.elster_transfer.elster_submission_approved') }}
      <span class="cursor-pointer icon-container">
        <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']" :hideTriggers="['click']" class="inline">
        <img class="help_icon  cursor-pointer" :src="getAssetPath('help_green.svg')"/>
        <template #popper>
          <div class="tooltip-content">
            <div class="space-y-4">
              <p class=" popover-text">{{ $t('declarations.detail.elster_auto_submission.disable_infos.info1') }}</p>
              <p class=" popover-text">{{ $t('declarations.detail.elster_auto_submission.disable_infos.info2') }}</p>
              <p class=" popover-text">{{ $t('declarations.detail.elster_auto_submission.disable_infos.info3') }}</p>
            </div>
          </div>
        </template>
      </VDropdown>
        <span class="underline" @click="deactivateElsterAutoSubmission">{{
            $t('declarations.detail.buttons.elster_transfer.deactivate_elster_submission')
          }}</span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: {
      type: String,
      default: ""
    },
    subText: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    rightIcon: {
      type: String,
      default: ""
    },
    secondary: {
      type: Boolean,
      default: false
    },
    isTextButton: {
      type: Boolean,
      default: false
    },
    delete: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    marginauto: {
      type: Boolean,
      default: false
    },
    blackText: {
      type: Boolean,
      default: false
    },
    isSubmenu: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    fullwidth: {
      type: Boolean,
      default: false
    },
    isSlim: {
      type: Boolean,
      default: false
    },
    elsterAutoSubmissionApproved: {
      type: Boolean,
      default: false
    },
    withTooltipText: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ""
    }
  },

  data() {
    return {}
  },
  computed: {
    CustomClasses() {
      return [
        {"secondary": this.secondary},
        {"text-button": this.isTextButton},
        {"fullwidth": this.fullwidth},
        {"min-w-fit": this.isSlim},
        {"marginauto": this.marginauto},
        {"delete": this.delete},
        {"disabled": this.disabled},
        {"input_loader": this.loading}
      ]
    },

  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
    deactivateElsterAutoSubmission() {
      this.$emit('deactivateElsterAutoSubmission')
    }
  }
}
</script>

<style scoped lang="scss">
.button {
  background-color: $primary;
  color: white;
  height: 32px;
  display: inline-flex;
  flex-direction: row;
  max-width: 390px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;

  &:not(.min-w-fit) {
    min-width: 168px;
  }

  &:hover {
    background-color: $button-hover-color;
  }

  &.fullwidth {
    max-width: 100%;
  }

  &.marginauto {
    margin: auto;
  }

  &.secondary {
    background-color: var(--secondary-button-bg-color);
    box-sizing: border-box;
    color: #404040;
    border-radius: inherit;

    &:hover {
      background-color: #AEAEAE
    }
  }

  &.disabled {
    background-color: #F4F1F3 !important;
    box-sizing: border-box;
    color: #AEAEAE;
    cursor: default;

    &:hover {
      background-color: #E9E9E9;
    }
  }

  &.delete {
    background-color: rgba(213, 89, 89, 1);
    box-sizing: border-box;
    color: white;

    &:hover {
      background-color: #ae3939
    }
  }

  &.text-button {
    background-color: transparent;
    color: theme('colors.black');

    &:hover {
      background-color: theme('colors.gray_light');
    }
  }
}

.button-text {
  font-family: 'Segoe UI', sans-serif;
  color: $button-text-color;
  white-space: nowrap;

  &.secondary {
    color: theme('colors.muted_black');
  }

  &.disabled {
    color: #A4A4A4 !important;
  }

  &.delete {
    color: white;
  }

  &.text-button {
    color: theme('colors.black');
  }
}

.input_loader {
  position: relative;

  i.pi-spinner {
    position: absolute;
    right: 5px;
    top: 6px;
  }

  input {
    padding-right: 30px;
  }
}

.img {
  width: 17px;
  height: 21px;
}

.img-right {
  width: 17px;
  height: 21px;
  margin-left: 8px;
}

.button_bottom_text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: center;
  font-size: 15px;
}

.button_cancel {
  p, div {
    color: #404040;
    background-color: #ccccccfe !important;
  }
}

.help_icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  display: inline;
}

.icon-container {
  display: inline;
  float: right;
}
.help {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

</style>
