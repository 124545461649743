<template>
  <Dialog
    :modal="true"
    :closable="false"
    :closeOnEscape="false"
    :visible.sync="isVisible"
    :containerStyle="{width: '60%'}"
    :contentStyle="{flex: '1'}"
    :baseZIndex="11000"
  >
    <template #header>
      <h3 class="text-xl font-bold">
        <span>{{ $t('settings.taxCategories.reorg.fusion_workflow.modal_title') }}</span>
      </h3>
    </template>

    <img
      :src="getAssetPath('hour_glass.svg')"
      class="icon-dashboard block size-20 mx-auto mb-6"
    />

    <div>
      <p class="pb-4">{{ $t('settings.taxCategories.reorg.fusion_workflow.modal_content_1') }}</p>
      <p>{{ $t('settings.taxCategories.reorg.fusion_workflow.modal_content_2') }}</p>
    </div>

    <template v-slot:footer>
      <div class="flex flex-row-reverse">
        <Button
          :text="$t('sidebar.popup.logout')"
          :primary="true"
          @click="logout"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import {appMixin} from '@/core/utils/mixin';

export default {
  name: 'AccountFusionModal',
  components: {
    Dialog,
  },
  mixins: [appMixin],
  data() {
    return {
      isVisible: true
    }
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  max-width: 700px;

  .p-dialog-header {
    justify-content: center;
  }
}
</style>
